body {
  font-family: Arial, sans-serif; /* Ustawienie podstawowej czcionki */
  line-height: 1.6; /* Wysokość linii, aby tekst był bardziej czytelny */
  background-color: #1e1e1e; /* Ciemne tło */
  color: #c7c7c7; /* Jasnoszary tekst */
  margin: 0;
  padding: 0;
}

.container {
  display: grid;
  grid-template-rows: 5% auto 5%;
  min-height: 100vh;
  grid-template-columns: 5% 90%;
  justify-content: center;
  background-color: #1e1e1e;
  gap: 0.8%;
}

.Baner {
  grid-column: 1 / 3; /* Zajmuje oba miejsca w pierwszym wierszu */
  display: flex;
  justify-self: center;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  background-color: #282828;
  color: #ffffff;
  width: 100%;
  border-radius: 10px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.content {
  background-color: #2e2e2e;
  color: #c7c7c7;
  width: 70%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  justify-self: center;
  border-radius: 10px;
  padding: 40px; /* Dodanie paddingu dla lepszego wyglądu */
  max-width: 800px; /* Ograniczenie szerokości kontenera z tekstem */
  margin: 0 auto; /* Wycentrowanie kontenera */
}

.footer {
  grid-column: 1 / 3; /* Zajmuje oba miejsca w pierwszym wierszu */
  background-color: #242424;
  color: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  justify-items: center;
  padding: 20px; /* Dodanie paddingu, aby poprawić wygląd */
}

.sideMenu {
  background-color: #333333;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  height: auto;
  align-self: start;
  position: sticky;
  top: 140px;
  z-index: 110;
  padding: 20px; /* Dodanie paddingu dla lepszego wyglądu */
}

.menu {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Odstępy między opcjami */
}

.menu-item {
  text-decoration: none;
  color: #61dafb;
  font-size: 18px;
  padding: 10px 15px;
  border: 2px solid #61dafb;
  border-radius: 5px;
  background-color: #333333;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.menu-item:hover {
  background-color: #3381ff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

p {
  margin-bottom: 1.5em; /* Odstęp między akapitami */
}

h1, h2, h3, h4, h5, h6 {
  color: #61dafb; /* Kontrastujący kolor nagłówków */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr; /* Uproszczony układ na mniejszych ekranach */
  }

  .content {
    width: 90%; /* Dostosowanie szerokości treści na węższych ekranach */
    padding: 20px; /* Zmniejszenie paddingu na mniejszych ekranach */
  }

  .sideMenu {
    width: 100%; /* Side menu zajmuje całą szerokość na mniejszych ekranach */
    position: relative;
    top: 0;
  }
}
